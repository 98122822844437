import { useCallback } from 'react';
import { useGuest } from '@/context/guest';
import useMutation from '@/hooks/useMutation';
import noop from '@invitato/helpers/dist/noop';

import useCobaLink from '@/hooks/useCobaLink';

import { ACTION_POST_TYPE, API_TABLE_TYPE, RSVP_STATUS } from '@/constants/api';

/**
 * @function useRsvp
 * @description a custom hook that return rsvp function
 */
function useRsvp() {
  const { onValidateCobaLink } = useCobaLink();
  const { guest } = useGuest() || {};
  const { code, status } = guest || {};

  const { onMutation } = useMutation(API_TABLE_TYPE.rsvp, ACTION_POST_TYPE.updateRsvp);

  /**
   * @function handleOpenInvitation
   * @description function to update invitation status
   * @param {Function} onSuccess
   * @param {Function} onFailed
   */
  const handleOpenInvitation = useCallback(async (onSuccess = noop, onFailed = noop) => {
    const isEligible = [RSVP_STATUS.waiting, RSVP_STATUS.sent, ''].includes(status);
    // We only update status invitation to `OPENED`
    // If status is empty or waiting only
    if (code && isEligible) {
      const options = `guest_code=${code}` + `&status=${RSVP_STATUS.opened}` + `&guest_quota=${guest.guest_quota}` + `&guest_quota_2=${guest.guest_quota_2}`;
      onValidateCobaLink(async () => {
        const result = await onMutation(options);

        if (result.success) {
          onSuccess();
        } else {
          onFailed();
        }
      });
    }
  }, []);

  /**
   * @function handleUpdateRsvp
   * @description custom handler to update rsvp data in database
   */
  const handleUpdateRsvp = async ({
    name = '',
    address = '',
    countryCode = '',
    phoneNumber = '',
    guestQuota = '',
    guestQuota2 = '',
    totalGuest = '',
    totalGuest2 = '',
    isAttended = '',
    email = '',
    guestCode,
    updateStatus,
    attendInfo = '',
    system_info = '',
    onSuccess = noop,
    onFailed = noop,
  }) => {
    const options =
      `guest_code=${guestCode || code}` +
      `&name=${encodeURIComponent(name)}` +
      `&country_code=${countryCode}` +
      `&address=${encodeURIComponent(address)}` +
      `&phone_number=${phoneNumber}` +
      `&guest_quota=${guestQuota}` +
      `&guest_quota_2=${guestQuota2}` +
      `&total_guest=${totalGuest}` +
      `&total_guest_2=${totalGuest2}` +
      `&status=${updateStatus || (isAttended ? RSVP_STATUS.attend : RSVP_STATUS.notAttend)}` +
      `&attend_info=${encodeURIComponent(attendInfo)}` +
      `&email=${email}` +
      `&system_info=${encodeURIComponent(system_info)}`;

    onValidateCobaLink(async () => {
      const result = await onMutation(options);

      if (result.success) {
        onSuccess();
      } else {
        onFailed();
      }
    });
  };

  return { onOpenInvitation: handleOpenInvitation, onUpdateRsvp: handleUpdateRsvp };
}

export default useRsvp;
